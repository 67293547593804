import CRUDService from '@/services/hrsg/CRUDService';

export class ReviewService extends CRUDService {
  constructor () {
    super('reviews.reviews');
  }

  async completeSetup (id, data) {
    const response = await this.request({
      endpoint: `${this.endpoint}.complete-setup`,
      pathParams: this.getPathvars({ id }),
      data
    });

    return response?.payload?.data ?? [];
  }

  async verifyAssessments (id, data) {
    const response = await this.request({
      endpoint: `${this.endpoint}.verify-assessments`,
      pathParams: this.getPathvars({ id }),
      data
    });

    return response?.payload?.data ?? [];
  }
}

export default new ReviewService();
